import React from "react";
import { ILogoProps } from "../react-app-env";
import { Link } from 'react-router-dom';


export class Logo extends React.Component<ILogoProps, any> {

    render() {
        const logo = <h1 className={this.props.className}>U L L A <br />K A J A V A</h1>;
        return this.props.isLink ? <Link style={{ color: "white", textDecoration:"none" }} to="/">{logo}</Link> : logo;
    }
}