import React from "react";
import { Logo } from "../Elements/Logo";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export class ReorderImages extends React.Component {


    onDragEnd() {

    }

    render() {
        return <div className="container">
            <Logo className="small-logo" isLink={true} />
            <h1>Kuvien järjestys</h1>
            <DragDropContext onDragEnd={this.onDragEnd}>
            </DragDropContext>
            </div>
    }
}