import React from "react";
import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import { Button } from '../Elements/Button';
import { observer } from "mobx-react";
import { Logo } from "../Elements/Logo";


export const UploadCV = observer(class UploadCV extends React.Component {

    CVFile: File | undefined;
    Saving = false;

    constructor(props: any) {
        super(props);
        makeObservable(this,{
            CVFile: observable,
            Saving: observable,
            onFileDrop: action,
            clearFile: action,
            save: action
        })
    }

    onFileDrop(acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) {
        this.CVFile = acceptedFiles[0];
    }

    clearFile() {
        this.CVFile = undefined;
    }

    save() {
        if (this.Saving == false) {
            this.Saving = true;

            var formData = new FormData();

            formData.append('file', this.CVFile);

            fetch('/api/Image/UploadCV', {
                method: 'post',
                headers: {
                    'Accept': 'application/json'
                },
                body: formData
            }).then(() => {
                //window.location.href = "/";
            });

        }
    }

    cancel() {
        if (window.confirm("Oletko varma, että haluat lähteä tallentamatta?")) {
            window.location.href = "/";
        }
    }

    render() {
        return <div className="container">
            <Logo className="small-logo" isLink={true} />
            <h1>Lataa CV</h1>
            {(typeof this.CVFile === "undefined") ?
                <Dropzone onDrop={this.onFileDrop.bind(this)}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>Raahaa tiedosto tähän tai klikkaa tästä.</p>
                        </div>
                    )}
                </Dropzone> :
                <div className="u-flex u-justify-content u-align-items-center u-flex-direction-column">
                    <div className="u-flex u-justify-content">
                        <h2>{this.CVFile.name}</h2>
                    </div>

                    <Button onClick={this.clearFile.bind(this)}>Lataa uusi tiedosto</Button>
                </div>
            }

            <div className="u-flex u-justify-content-space-between">
                <Button onClick={this.save.bind(this)}>Tallenna</Button>
                <Button className="red" onClick={this.cancel.bind(this)}>Peruuta</Button>
            </div>

        </div>
    }
})