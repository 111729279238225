import React from "react";
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { observer } from "mobx-react";
import { observable, action, toJS, makeAutoObservable } from "mobx";
import { Button } from '../Elements/Button';
import { Image } from "../Models/Image";
import { RouteComponentProps } from "react-router";
import { Logo } from "../Elements/Logo";
import { imageTypes } from "../Stores/GalleryStore";

export const ImageEditor = observer(class ImageEditor extends React.Component<RouteComponentProps<any>, any> {
    ImageFile: File | undefined;

    Saving = false;

    Image: Image = new Image();

    constructor(props: RouteComponentProps<any>) {
        super(props);
        if (typeof props.location.state !== "undefined") {
            this.Image = props.location.state as Image;
        }
        this.state = {
            error: false
        };
    }

    onImageDrop(acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) {
        this.ImageFile = acceptedFiles[0];
        this.Image.imagePath = URL.createObjectURL(acceptedFiles[0]);
    }

    clearImage() {
        this.ImageFile = undefined;
        this.Image.imagePath = "";
    }

    save() {
        if (this.Saving == false) {
            this.Saving = true;
            var formData = new FormData();

            formData.append('imagejson', JSON.stringify(toJS(this.Image)));
            formData.append('file', this.ImageFile);

            fetch('/api/Image/Upload', {
                method: 'post',
                headers: {
                    'Accept': 'application/json'
                },
                body: formData
            }).then(res => res ? res.json() : null).then((response: Image) => {
                    //window.location.href = "/kuva/" + response.path;
                }, e => this.setState({error: true}));
        }
    }

    delete() {
        if (window.confirm("Oletko varma, että haluat poistaa kuvan pysyvästi?")) {
            fetch('/api/Image/Delete?path=' + this.Image.path, {
                method: 'post',
                headers: {
                    'Accept': 'application/json'
                }
            }).then((res) => res.json()).then((response: number) => {
                window.location.href = "/";
            });
        }
    }

    cancel() {
        if (window.confirm("Oletko varma, että haluat lähteä tallentamatta?")) {
            if (this.Image.path !== null && typeof this.Image.path !== "undefined" && this.Image.path.length > 0) {
                window.location.href = "/kuva/" + this.Image.path;
            } else {
                window.location.href = "/";
            }

        }
    }

    render() {
        let isOld = this.Image.path !== null && typeof this.Image.path !== "undefined" && this.Image.path.length > 0
        return (
            <div className="container">
                <Logo className="small-logo" isLink={true} />

                {isOld ?
                    <h1 className="u-text-center">Kuvan muokkaaminen</h1> : <h1 className="u-text-center">Uuden kuvan lisääminen</h1>}


                <div className="u-flex u-justify-content">
                    <div className="u-max-full-width">
                        <label htmlFor="name">Kuvan nimi:</label> <br />
                        <input type="text" onChange={(e) => this.Image.name = e.target.value} defaultValue={this.Image.name} className="large-input" id="name" placeholder="Nimi" />


                        {(typeof this.Image.imagePath === 'undefined' || this.Image.imagePath === "") ?
                            <Dropzone onDrop={this.onImageDrop.bind(this)}>
                                {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone" {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Raahaa kuva tähän tai klikkaa tästä.</p>
                                    </div>
                                )}
                            </Dropzone> :
                            <div className="u-flex u-justify-content u-align-items-center u-flex-direction-column">
                                <div className="u-flex u-justify-content">
                                    <img className="preview-image" src={this.Image.imagePath} />
                                </div>

                                <Button onClick={this.clearImage.bind(this)}>Vaihda kuva</Button>
                            </div>
                        }
                        <div>
                            <span>Tyyppi:</span> <br />
                            <ul className="button-list" style={{ margin: "20px 0px" }}>
                                {imageTypes.map((type, index) => (
                                    <li onClick={(e) => this.Image.type = type} key={index}>
                                        <span className={this.Image.type == type ? "active" : ""}>{type}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div>
                            <label htmlFor="size">Koko:</label> <br />
                            <input type="text" className="medium-input" id="size" onChange={(e) => this.Image.size = e.target.value} defaultValue={this.Image.size} placeholder="Koko" />
                        </div>

                        <div>
                            <label htmlFor="year">Vuosi:</label> <br />
                            <input type="text" className="medium-input" id="year" onChange={(e) => this.Image.year = e.target.value} defaultValue={this.Image.year} placeholder="Vuosi" />
                        </div>

                        <div>
                            <label htmlFor="price">Hinta:</label> <br />
                            <input type="text" className="medium-input" id="price" onChange={(e) => this.Image.price = e.target.value} defaultValue={this.Image.price} placeholder="Hinta" />
                        </div>

                        <div>
                            <label htmlFor="description">Kuvaus:</label> <br />
                            <input type="text" className="medium-input" id="description" onChange={(e) => this.Image.description = e.target.value} defaultValue={this.Image.description} placeholder="Kuvaus" />
                        </div>

                        <div>
                            <label htmlFor="technique">Tekniikka:</label> <br />
                            <input type="text" className="medium-input" id="technique" onChange={(e) => this.Image.technique = e.target.value} defaultValue={this.Image.technique} placeholder="Tekniikka" />
                        </div>

                        <br />
                        <br />
                        {this.state.error &&
                            <p className="error-message">Kuvan tallennuksessa tapahtui virhe.</p>
                        }
                        <div className="u-flex u-justify-content-space-between">
                            <Button className="red" onClick={this.cancel.bind(this)}>Peruuta</Button>
                            {isOld && <Button className="red" onClick={this.delete.bind(this)}>Poista kuva</Button>}
                            <Button onClick={this.save.bind(this)}>Tallenna kuva</Button>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
})