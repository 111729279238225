import React, { FormEvent, KeyboardEvent } from "react";
import { Logo } from "../Elements/Logo";
import { Button } from '../Elements/Button';
import { observer } from "mobx-react";
import adminStore from "../Stores/AdminStore";
import { observable, action, makeAutoObservable, makeObservable } from "mobx";

export const Login = observer(class Login extends React.Component {

    constructor(props: any) {
        super(props);
        makeObservable(this, {
            result: observable,
            counter: observable,
            onLogin: action
        });
        this.onLogin.bind(this);
    }

    result: boolean;
    counter: number = 0;

    onLogin(event: FormEvent<HTMLFormElement>) {
        event?.preventDefault();
        var user = (document.getElementsByName('username')[0] as HTMLInputElement).value;
        var pass = (document.getElementsByName('password')[0] as HTMLInputElement).value;
        adminStore.Login(user, pass, (result: boolean) => {
            this.result = result;
            if (this.result === false) {
                this.counter++;
            } else {
                window.location.href = "/";
            }
        });
    }

    render() {
        return <div className="login-wrapper">
            <Logo className="small-logo" isLink={true} />
            <form className="login-container" onSubmit={this.onLogin.bind(this)}>
                <h2>Kirjaudu</h2>
                {this.result === false && <p>Kirjautuminen epäonnistui ({this.counter})!</p>}
                <h4>Käyttäjätunnus:</h4>
                <input type="text" name="username" />
                <h4>Salasana:</h4>
                <input type="password" name="password"  />
                <br />
                <br />

                <Button>Kirjaudu</Button>
            </form>
        </div>
    }
})