import { observable, action, makeAutoObservable } from 'mobx';


class AdminStore {


    IsAdmin = false;

    constructor() {
        makeAutoObservable(this);
        this.CheckStatus();
        setInterval(this.CheckStatus, 60000);
    }

    CheckStatus() {
        fetch('/api/Login/Status', {
            method: 'get'
        }).then((res) => res.json()).then((response: boolean) => {
            console.log(response);
            this.IsAdmin = response;
        });
    }

    Login(username: string, password: string, callback: any) {
        fetch('/api/Login', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password
            })
        }).then((res) => res.json()).then((response: boolean) => {
            this.IsAdmin = response;
            callback(response);
        });
    }

    Logout() {
        fetch('/api/Login/Logout', {
            method: 'get'
        }).then((res) => { this.IsAdmin = false; });
    }

}

const adminStore = new AdminStore();

export default adminStore;