import React, { Children } from "react";
import { Modal } from "react-bootstrap";
import { observable, action, makeObservable } from "mobx";
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import adminStore from "../Stores/AdminStore";


export const AdminPanel = observer(class AdminPanel extends React.Component {

    constructor(props: any) {
        super(props);
        makeObservable(this, {
            PanelOpen: observable,
            onOpen: action,
            onClose: action,
            onLogout: action
        })
    }
    PanelOpen = false;

    onOpen() {
        this.PanelOpen = true;
    }

    onClose() {
        this.PanelOpen = false;
    }

    onLogout() {
        if (window.confirm("Oletko varma, että haluat kirjautua ulos?")) {
            this.PanelOpen = false;
            adminStore.Logout();
        }
    }

    render() {
        return (
            <>
                <i onClick={this.onOpen.bind(this)} className="material-icons admin-panel-trigger">
                    settings
                    </i>

                <Modal show={this.PanelOpen} onHide={this.onClose.bind(this)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Hallinta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div id="admin-panel-action-list">

                            {this.props.children}
                            <Link onClick={this.onClose.bind(this)} to="/image-editor">
                                <img src="icons/add_icon.png" /><span>Lisää uusi kuva</span>
                            </Link>
                            <Link onClick={this.onClose.bind(this)} to="/lataa-cv">
                                <img src="icons/description_icon.png" /><span>Vaihda CV</span>
                            </Link>
                            <Link onClick={this.onClose.bind(this)} to="/kuvien-jarjestys">
                                <img src="icons/reorder_icon.png" /><span>Järjestele kuvia</span>
                            </Link>
                            <a onClick={this.onLogout.bind(this)} href="javascript:void(0)">
                                <img src="icons/logout_icon.png" /><span>Kirjaudu ulos</span>
                            </a>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
})