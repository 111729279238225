import { action, observable, IObservableArray, makeAutoObservable } from "mobx";
import { Image } from "../Models/Image";
import { CV } from "../Models/CV";

class GalleryStore {

    constructor(){
        makeAutoObservable(this);
    }

    Images: IObservableArray<Image> = observable([]);

    CV = new CV();

    SliderImages: IObservableArray<Image> = observable([]);

    LoadImages(type: string = null) {
        fetch('/api/Image/GetImages' + (type != null ? "?type=" + type : ""), {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        }).then((res) => res.json()).then((response: Image[]) => {
            this.Images.replace(response);
        });
    }

    LoadSliderImages() {
        fetch('/api/Image/GetSliderImages', {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        }).then((res) => res.json()).then((response: Image[]) => {
            this.SliderImages.replace(response);
        });
    }
}

const galleryStore = new GalleryStore();

export const imageTypes = [
    "Akryyli",
    "Akvarelli",
    "Sekatekniikka"
];

export default galleryStore;