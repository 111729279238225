import * as React from 'react';
import { Route } from 'react-router';
import { Home } from './components/Home';
import adminStore from './Stores/AdminStore';
import { ImageEditor } from './components/ImageEditor';
import { Gallery } from './components/Gallery';
import { AdminPanel } from './Elements/AdminPanel';
import { ImageDisplay } from './components/ImageDisplay';
import { Contact } from './components/Contact';
import { UploadCV } from './components/UploadCV';
import { Observer } from 'mobx-react';
import { Login } from './components/Login';
import { ReorderImages } from './components/ReorderImages';
import NotFoundError from './components/NotFoundError';
import { Router, Switch } from 'react-router-dom';

export class App extends React.Component {

    render() {
        console.log(this);
        return (
            <Observer>{() => (<>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/galleria/:type?' component={Gallery} />
                    <Route path='/kuva/:type?/:image' component={ImageDisplay} />
                    <Route exact path='/yhteystiedot' component={Contact} />

                    {adminStore.IsAdmin === false && <Route path='/kirjaudu' component={Login} />}
                    {adminStore.IsAdmin && <Route path='/image-editor' component={ImageEditor} />}
                    {adminStore.IsAdmin && <Route path='/kuvien-jarjestys' component={ReorderImages} />}
                    {adminStore.IsAdmin && <Route exact path='/lataa-cv' component={UploadCV} />}

                    <Route component={NotFoundError} />
                </Switch>
                {adminStore.IsAdmin &&
                        <AdminPanel>
                        </AdminPanel>
                    }
            </>)
            }
            </Observer>
        );
    }
}
