import React from "react";
import { RouteComponentProps } from "react-router";
import { IImageDisplayUrlProps, IImageDisplayResponse } from '../react-app-env';
import { Image } from "../Models/Image";
import { observer } from "mobx-react";
import { action, makeObservable, observable, toJS } from "mobx";
import { Button } from '../Elements/Button';
import { Link } from 'react-router-dom';
import adminStore from "../Stores/AdminStore";
import { Logo } from "../Elements/Logo";



export const ImageDisplay = observer(class ImageDisplay extends React.Component<RouteComponentProps<IImageDisplayUrlProps>, any> {

    image: Image = null;
    previous: string;
    next: string;

    constructor(props: RouteComponentProps<IImageDisplayUrlProps>) {
        super(props);
        makeObservable(this,{
            image: observable,
            previous: observable,
            next: observable,
            loadImage: action
        });
        this.loadImage(this.props.match.params.image);
    }

    componentWillReceiveProps(nextProps: RouteComponentProps<IImageDisplayUrlProps>) {
        this.loadImage(nextProps.match.params.image);
    }

    loadImage(image: string) {
        fetch('/api/Image/GetImage?url=' + image, {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        }).then((res) => res.json()).then((response: IImageDisplayResponse) => {
            this.image = response.image;
            this.previous = response.previousImage;
            this.next = response.nextImage;
        });
    }

    render() {

        if (this.image !== null) {
            return <div className="container image-display">
                <Logo className="small-logo" isLink={true} />
                <h1>{this.image.name}</h1>
                <div className="u-flex u-justify-content">
                    <img className="" src={this.image.imagePath} />
                </div>

                <div className="u-flex u-align-items-center u-flex-direction-column">
                    <table className="information-table">
                        <tbody>
                            {this.image.price !== null &&
                                <tr>
                                    <td>Hinta:</td>
                                    <td>{this.image.price}</td>
                                </tr>
                            }

                            {this.image.size !== null &&
                                <tr>
                                    <td>Koko:</td>
                                    <td>{this.image.size}</td>
                                </tr>
                            }
                            {this.image.year !== null &&
                                <tr>
                                    <td>Vuosi:</td>
                                    <td>{this.image.year}</td>
                                </tr>
                            }

                            {this.image.technique !== null &&
                                <tr>
                                    <td>Tekniikka:</td>
                                    <td>{this.image.technique}</td>
                                </tr>
                            }

                            {this.image.description !== null &&
                                <tr>
                                    <td>Kuvaus:</td>
                                    <td>{this.image.description}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <ul className="button-list">
                        {this.previous !== null &&
                            <li>
                                <Link to={"/kuva/" + this.previous}>Edellinen</Link>
                            </li>
                        }
                        {this.next !== null &&
                            <li>
                                <Link to={"/kuva/" + this.next}>Seuraava</Link>
                            </li>
                        }
                    </ul>


                    {adminStore.IsAdmin &&
                        <Link style={{ marginTop: "20px", textDecoration: "none" }} to={{ pathname: "/image-editor", state: toJS(this.image) }}>
                            <Button>Muokkaa kuvaa</Button>
                        </Link>
                    }

                </div>

            </div>
        } else {
            return <></>
        }
    }
})