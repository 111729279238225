import 'bootstrap/dist/css/bootstrap.css';
import './scss/utilities.scss';
import './scss/global.scss';
import 'material-design-icons/iconfont/material-icons.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { App } from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl || ""}>
    <App />
  </BrowserRouter>,
  rootElement);

registerServiceWorker();
