import React from "react";
import galleryStore, { imageTypes } from "../Stores/GalleryStore";
import { observer } from "mobx-react";
import adminStore from "../Stores/AdminStore";
import { AdminPanel } from '../Elements/AdminPanel';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Logo } from "../Elements/Logo";
import { IGalleryUrlProps } from "../react-app-env";


export const Gallery = observer(class Gallery extends React.Component<RouteComponentProps<IGalleryUrlProps>, any> {

    constructor(props: RouteComponentProps<IGalleryUrlProps>) {
        super(props);
        galleryStore.LoadImages(this.props.match.params.type);
    }

    componentWillReceiveProps(nextProps: RouteComponentProps<IGalleryUrlProps>) {
        galleryStore.LoadImages(nextProps.match.params.type);
    }

    render() {
        const typeFromUrl = this.props.match.params.type || "";


        return <>
            <div className="container">
                <Logo className="small-logo" isLink={true} />
                <ul className="button-list">
                    <li>
                        <Link className={!imageTypes.includes(typeFromUrl) ? "active" : ""} to="/galleria">Kaikki</Link>
                    </li>
                    {imageTypes.map((type, index) => (
                        <li key={index}>
                            <Link to={"/galleria/" + type} className={type == typeFromUrl ? "active" : ""}>{type}</Link>
                        </li>
                    ))}
                </ul>
                <div className="gallery-grid">
                    {galleryStore.Images.map((image, index) =>
                        <Link to={"/kuva/" + image.path} className="gallery-image" key={index}>
                            <div className="gallery-thumbnail" style={{ backgroundImage: 'url(' + encodeURI(image.thumbnailPath) + ')' }} />
                        </Link>
                    )}
                </div>

            </div>
        </>
    }
})