import { makeAutoObservable, observable } from "mobx";

export class Image {

    constructor() {
        makeAutoObservable(this);
    }

    name: string;

    order: string;

    path: string;

    type: string;

    imagePath: string;

    thumbnailPath: string;

    size: string;

    year: string;

    price: string;

    description: string;

    technique: string;

}