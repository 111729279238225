import React from "react";
import { Logo } from "../Elements/Logo";


export class Contact extends React.Component {

    render() {
        return <div className="container">
            <Logo className="small-logo" isLink={true} />
            <div className="u-text-center">
                <h1>Yhteystiedot</h1>
                ULLA KAJAVA<br /><br />
                puh. +358400950266<br /><br />
                ulla.kajava@iki.fi<br /><br />
                työhuone: Taiteilijayhteisö Kolmas kerros ry.<br /><br />
Hämeentie 157 (Arabianranta) Helsinki
            </div>
        </div>
    }
}