import * as React from 'react';
import adminStore from '../Stores/AdminStore';
import { AdminPanel } from '../Elements/AdminPanel';
import { Link } from 'react-router-dom';
import { Logo } from '../Elements/Logo';
import { ImageSlider } from '../Elements/ImageSlider';
import galleryStore, { imageTypes } from '../Stores/GalleryStore';
import { observer } from 'mobx-react';

export const Home = observer(class Home extends React.Component {


    render() {
        return (
            <div className="container u-text-center home">
                <ImageSlider />

                <Logo />

                <div className="pages-list">
                    <Link to="/yhteystiedot">Yhteystiedot</Link>
                    {typeof galleryStore.CV.path !== "undefined" &&
                        <a href={galleryStore.CV.path}>CV</a>
                    }

                </div>

                <br />
                <h2><Link to="/galleria">Galleria</Link></h2>
                <ul className="button-list">
                    <li>
                        <Link to="/galleria">Kaikki</Link>
                    </li>
                    {imageTypes.map((type, index) => (
                        <li key={index}>
                            <Link to={"/galleria/" + type}>{type}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
})