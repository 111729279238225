import React from "react";
import { action, makeObservable, observable } from "mobx";
import { ICV } from "../react-app-env";


export class CV {

    constructor() {
        makeObservable(this, {
            path: observable,
            name: observable,
            getCV: action
        });
        if (typeof this.path == "undefined") {
            this.getCV();
        }
    }

    path: string;
    name: string;

    getCV() {
        fetch('/api/Image/GetDocument?type=0', {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            }
        }).then((res) => res.json()).then((response: ICV) => {
            this.path = response.path;
            this.name = response.name;
        }).catch((e) => console.error(e));
    }
}