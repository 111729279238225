import React from "react";
import Slider, { Settings } from "react-slick";
import { Link } from 'react-router-dom';
import galleryStore from "../Stores/GalleryStore";
import { observer } from "mobx-react";
import { makeAutoObservable, observable } from "mobx";


export const ImageSlider = observer(class ImageSlider extends React.Component {

     settings: Settings = {
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };

    constructor(props: any) {
        super(props);
        if (galleryStore.SliderImages.length < 1) {
            galleryStore.LoadSliderImages();
        }
        
    }

    render() {
        return <Slider {...this.settings}>
            {galleryStore.SliderImages.map((image, index) => (
                <Link to={"/kuva/" + image.path} className="gallery-image" key={index}>
                    <div className="gallery-thumbnail" style={{ backgroundImage: 'url(' + image.thumbnailPath + ')' }} />
                </Link>
            ))}
        </Slider>
    }
})